import { Pipe, PipeTransform, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '../../tokens/token';

@Pipe({
  name: 'urlPrefix',
  standalone: true,
})
export class UrlPrefixPipe implements PipeTransform {
  public readonly envUrl: string = inject(ENVIRONMENT_TOKEN);

  transform(url: string): string | undefined {
    if (!url) {
      return;
    }
    return this.envUrl + url;
  }
}
