import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  NotificationsListRequestParams,
  NotificationsPollListRequestParams,
  NotificationsService,
  Notification,
} from '@cat-ai-us-fe/api';

export const DEFAULT_NOTIFICATION_DISMISS_DURATION = 3000;

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private notificationsService: NotificationsService,
    private router: Router,
  ) {}

  getNotifications(params: NotificationsListRequestParams = {}) {
    return this.notificationsService.notificationsList(params);
  }

  getNotificationsPoll(params: NotificationsPollListRequestParams = {}) {
    return this.notificationsService.notificationsPollList(params);
  }

  dismissAll() {
    return this.notificationsService.notificationsDismissAllUpdate();
  }

  dismiss(id: number) {
    return this.notificationsService.notificationsDismissUpdate({ id: id });
  }

  navigateTo(notification: Notification) {
    switch (notification.kind) {
      case Notification.KindEnum.Training:
        this.router.navigate([`trainings/${notification.related}`]);
        break;
      case Notification.KindEnum.Material:
        this.router.navigate([
          `/dashboard/material/notype/review/${notification.related}`,
        ]);
        break;
      case Notification.KindEnum.Task:
        this.router.navigateByUrl(`task-manager/${notification.related}`);
    }
  }

  subscribe() {
    return this.notificationsService.notificationsSubscribeUpdate();
  }
}
