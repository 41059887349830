/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FormQuestionChoice } from './form-question-choice';
import { QuestionRequestType } from './question-request-type';

export interface FormQuestion {
  readonly id: number;
  title: string;
  /**
   * * `input_number` - Input Number * `input_email` - Input Email * `input_phone` - Input Phone * `input_text` - Input Text * `request` - Request * `radio` - Radio * `select` - Select * `checkbox` - Checkbox * `textarea` - Textarea * `date` - Date * `file` - File * `canvas` - Canvas
   */
  data_type: FormQuestion.DataTypeEnum;
  choices?: Array<FormQuestionChoice>;
  readonly created_at: string;
  readonly updated_at: string;
  readonly created_by: number;
  placeholder?: string | null;
  is_multiselect?: boolean;
  is_required?: boolean;
  order?: number;
  column_start?: number | null;
  column_width?: number;
  request_field_route?: string | null;
  readonly request_params: QuestionRequestType;
  request_field_mapping?: any | null;
}
export namespace FormQuestion {
  export type DataTypeEnum =
    | 'input_number'
    | 'input_email'
    | 'input_phone'
    | 'input_text'
    | 'request'
    | 'radio'
    | 'select'
    | 'checkbox'
    | 'textarea'
    | 'date'
    | 'file'
    | 'canvas';
  export const DataTypeEnum = {
    InputNumber: 'input_number' as DataTypeEnum,
    InputEmail: 'input_email' as DataTypeEnum,
    InputPhone: 'input_phone' as DataTypeEnum,
    InputText: 'input_text' as DataTypeEnum,
    Request: 'request' as DataTypeEnum,
    Radio: 'radio' as DataTypeEnum,
    Select: 'select' as DataTypeEnum,
    Checkbox: 'checkbox' as DataTypeEnum,
    Textarea: 'textarea' as DataTypeEnum,
    Date: 'date' as DataTypeEnum,
    File: 'file' as DataTypeEnum,
    Canvas: 'canvas' as DataTypeEnum,
  };
}
