import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { inject } from '@angular/core';
import { ActiveUserState } from '../store/active-user.state';

export const RoleGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
): boolean => {
  const store = inject(Store);

  const allowedRoles = next.data.roles as Array<string>;
  const role = store.selectSnapshot(ActiveUserState.getRole);

  if (role && allowedRoles.includes(role)) {
    return true;
  } else {
    //TODO: Redirect or handle unauthorized access
    return false;
  }
};
