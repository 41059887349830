import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToOptions',
  standalone: true,
})
export class EnumToOptionsPipe implements PipeTransform {
  transform(value: any): { name: string; id: string }[] {
    return Object.keys(value)
      ?.filter((v) => isNaN(Number(v)))
      .map((key) => {
        return {
          name: value[key] && value[key].replace('_', ' '),
          id: value[key],
        };
      });
  }
}
