/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface FileRequest {
  url: string;
  /**
   * * `plain` - Plain * `pdf` - Pdf * `docx` - Docx * `xlsx` - Xlsx * `unknown` - Unknown
   */
  extension?: FileRequest.ExtensionEnum;
  /**
   * * `created` - Created * `upload_success` - Upload Success * `upload_failed` - Upload Failed * `parsed_success` - Parsed Success * `parsed_failed` - Parsed Failed
   */
  status?: FileRequest.StatusEnum;
}
export namespace FileRequest {
  export type ExtensionEnum = 'plain' | 'pdf' | 'docx' | 'xlsx' | 'unknown';
  export const ExtensionEnum = {
    Plain: 'plain' as ExtensionEnum,
    Pdf: 'pdf' as ExtensionEnum,
    Docx: 'docx' as ExtensionEnum,
    Xlsx: 'xlsx' as ExtensionEnum,
    Unknown: 'unknown' as ExtensionEnum,
  };
  export type StatusEnum =
    | 'created'
    | 'upload_success'
    | 'upload_failed'
    | 'parsed_success'
    | 'parsed_failed';
  export const StatusEnum = {
    Created: 'created' as StatusEnum,
    UploadSuccess: 'upload_success' as StatusEnum,
    UploadFailed: 'upload_failed' as StatusEnum,
    ParsedSuccess: 'parsed_success' as StatusEnum,
    ParsedFailed: 'parsed_failed' as StatusEnum,
  };
}
