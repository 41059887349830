import { Component, Input } from '@angular/core';

@Component({
  selector: 'cat-ai-card',
  standalone: true,
  templateUrl: './card.component.html',
})
export class CardComponent {
  @Input() wrapperClasses!: string;
}
