export const setDateWithTime = (
  date: string | undefined,
  time: string | undefined,
) => {
  const value = new Date(date as string);
  const dateWith = new Date(
    value.getTime() - value.getTimezoneOffset() * 60000,
  );

  if (!time) {
    return dateWith.toISOString();
  } else {
    const hhMM = time.split(':').map((t) => Number(t));
    (dateWith as any).setHours(...hhMM);
    return dateWith.toISOString();
  }
};

export const getTimeFromDate = (date: string) => {
  const value = new Date(date as string);
  const dateWith = new Date(
    value.getTime() + value.getTimezoneOffset() * 60000,
  );
  return dateWith.toTimeString().slice(0, 5);
};
