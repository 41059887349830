/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobCategoryRequest } from './job-category-request';
import { CompanyGroupRequest } from './company-group-request';

export interface PatchedParticipantRequest {
  email?: string;
  first_name?: string;
  last_name?: string;
  /**
   * * `owner` - Owner * `admin` - Admin * `supervisor` - Supervisor * `user` - User * `employee` - Employee * `nobody` - Nobody
   */
  role?: PatchedParticipantRequest.RoleEnum;
  job_category?: JobCategoryRequest;
  avatar_url?: string | null;
  groups?: Array<CompanyGroupRequest>;
}
export namespace PatchedParticipantRequest {
  export type RoleEnum =
    | 'owner'
    | 'admin'
    | 'supervisor'
    | 'user'
    | 'employee'
    | 'nobody';
  export const RoleEnum = {
    Owner: 'owner' as RoleEnum,
    Admin: 'admin' as RoleEnum,
    Supervisor: 'supervisor' as RoleEnum,
    User: 'user' as RoleEnum,
    Employee: 'employee' as RoleEnum,
    Nobody: 'nobody' as RoleEnum,
  };
}
