import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Permission, PermissionsService } from '@cat-ai-us-fe/api';
import { of, switchMap } from 'rxjs';

export const PermissionGuard: CanActivateFn = (route) => {
  const permissionsService = inject(PermissionsService);
  const router = inject(Router);

  const requiredPermission = route.data.requiredPermission as string;

  return permissionsService
    .permissionsList({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pageSize: 'null',
    })
    .pipe(
      switchMap((res) => {
        const permissions = res as unknown as Permission[];
        const canView = permissions.some(
          (permission) => permission.codename === requiredPermission,
        );
        if (!canView) {
          router.navigate(['/dashboard']);
        }
        return of(canView);
      }),
    );
};
