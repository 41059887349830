import { State, Action, StateContext, Selector } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ClearUser, GetActiveUser } from './active-user.actions';
import { UserService } from '../services/user-service/user.service';
import { User } from '@cat-ai-us-fe/api';
export type ActiveUserModel = Partial<User>;

@State<ActiveUserModel>({
  name: 'user',
  defaults: {},
})
@Injectable()
export class ActiveUserState {
  constructor(private service: UserService) {}

  @Selector() static currentUser(state: ActiveUserModel) {
    return state;
  }

  @Selector()
  static getRole(state: ActiveUserModel) {
    return state.role;
  }

  @Action(GetActiveUser)
  getUser(ctx: StateContext<ActiveUserModel>) {
    const currentId = 'me' as unknown as number;
    return this.service.getCurrentUser(currentId).pipe(
      map((res) => {
        ctx.setState({ ...res });
      }),
    );
  }

  @Action(ClearUser)
  ClearUser(ctx: StateContext<ActiveUserModel>) {
    ctx.setState({});
  }
}
