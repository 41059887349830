/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DocumentVersion {
  readonly id: string;
  document: number;
  readonly created_at: string;
  readonly created_by: number;
  /**
   * * `not_generated` - Not Generated * `generated_pending` - Generated Pending * `generated_success` - Generated Success * `generated_failed` - Generated Failed
   */
  readonly status: DocumentVersion.StatusEnum;
  user_prompt?: string;
  readonly llm_original_response: string | null;
  document_body?: string | null;
  readonly text_body: string | null;
  custom_fields?: any | null;
  readonly generated_file_url: string | null;
  title?: string | null;
  /**
   * * `created` - Created * `updated` - Updated * `not_updated` - Not Updated
   */
  reason?: DocumentVersion.ReasonEnum | null;
  category?: number | null;
  form_number?: number | null;
  readonly material: number;
}
export namespace DocumentVersion {
  export type StatusEnum =
    | 'not_generated'
    | 'generated_pending'
    | 'generated_success'
    | 'generated_failed';
  export const StatusEnum = {
    NotGenerated: 'not_generated' as StatusEnum,
    GeneratedPending: 'generated_pending' as StatusEnum,
    GeneratedSuccess: 'generated_success' as StatusEnum,
    GeneratedFailed: 'generated_failed' as StatusEnum,
  };
  export type ReasonEnum = 'created' | 'updated' | 'not_updated' | 'null';
  export const ReasonEnum = {
    Created: 'created' as ReasonEnum,
    Updated: 'updated' as ReasonEnum,
    NotUpdated: 'not_updated' as ReasonEnum,
    Null: 'null' as ReasonEnum,
  };
}
