import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialRead } from '@cat-ai-us-fe/api';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DomSanitizer } from '@angular/platform-browser';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'cat-ai-material-preview',
  standalone: true,
  imports: [CommonModule, PdfViewerModule, MatProgressSpinnerModule],
  templateUrl: './material-preview.component.html',
})
export class MaterialPreviewComponent implements OnChanges {
  @Input() url!: string | undefined;
  @Input() loading = false;
  @Output() handleError = new EventEmitter();

  rendering = false;

  public MaterialType = MaterialRead.TypeEnum;
  constructor(protected _sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.url) {
      this.rendering = true;
    }
  }

  onSuccessRender() {
    this.rendering = false;
  }

  onError($event: any) {
    this.rendering = false;

    this.handleError.emit($event);
  }
}
