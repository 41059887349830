import { TitleCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'cat-ai-selector',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatInput,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    TitleCasePipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectorComponent),
    },
  ],
  templateUrl: './selector.component.html',
})
export class SelectorComponent<Option extends { [key: string | number]: any }>
  implements ControlValueAccessor
{
  @Input() options!: Option[] | null;
  @Input() enumOptions!: Option[] | null;
  @Input() label = '';
  @Input() formControlName = '';
  @Input() valueBindedProperty = 'id';
  @Input() labelBindedProperty = 'name';
  @Input() wrapperClasses = '';
  @Input() resetOption = false;
  @Input() _value: any;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter();

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
