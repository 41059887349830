import { NgClass, TitleCasePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReplaceUnderscorePipe } from '@cat-ai-us-fe/shared/util';

export type ChipColor =
  | 'blue'
  | 'green'
  | 'purple'
  | 'violet'
  | 'red'
  | 'gray'
  | 'orange';
export type ChipVariant = 'filled' | 'outlined';

@Component({
  selector: 'cat-ai-chip',
  standalone: true,
  imports: [NgClass, ReplaceUnderscorePipe, TitleCasePipe],
  templateUrl: './chip.component.html',
})
export class ChipComponent implements OnChanges {
  @Input() color: ChipColor = 'gray';
  @Input() variant: ChipVariant = 'filled';
  @Input() row: any = 'filled';
  @Input() bindedProperty!: string;
  @Input() colorsConfig: Record<string, ChipColor> | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    // TODO: check if there is better implementation
    if (
      (changes.row || changes.bindedProperty || changes.colorsConfig) &&
      changes.colorsConfig?.currentValue
    ) {
      const data =
        changes.row.currentValue[changes.bindedProperty?.currentValue];
      this.color = changes.colorsConfig?.currentValue[data];
    }
  }
}
