<div class="h-full flex flex-col">
  @if (loading || rendering) {
    <mat-spinner
      class="self-center"
      [strokeWidth]="3"
      [diameter]="50"
    ></mat-spinner>
  }

  <pdf-viewer
    [src]="url"
    class="w-full grow bg-white rounded-lg"
    (page-rendered)="onSuccessRender()"
    (error)="onError($event)"
    [zoom-scale]="'page-width'"
  ></pdf-viewer>
</div>
