import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { PaginationConfig, TableConfig } from '@cat-ai-us-fe/shared/util';
import { columns } from './change-logs.config';
import {
  MaterialChange,
  MaterialsChangelogListRequestParams,
} from '@cat-ai-us-fe/api';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'cat-ai-change-log',
  standalone: true,
  imports: [TableComponent],
  templateUrl: './change-log.component.html',
})
export class ChangeLogComponent implements OnChanges {
  @Input() data!: MaterialChange[] | null;
  @Input() pagination!: PaginationConfig;
  @Output() updateFilters = new EventEmitter<
    Partial<MaterialsChangelogListRequestParams>
  >();

  config!: TableConfig<MaterialChange>;

  ngOnChanges(): void {
    if (this.pagination) {
      this.config = {
        columns: columns,
        pagination: this.pagination,
      };
    }
  }
}
