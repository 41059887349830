/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface MaterialValidationUpdateRequest {
  material: number;
  validated_at?: string | null;
  validated_by: number;
  /**
   * * `pending` - Pending * `approved` - Approved * `changes_requested` - Changes Requested * `rejected` - Rejected
   */
  status?: MaterialValidationUpdateRequest.StatusEnum;
  reason?: string | null;
  review?: string | null;
}
export namespace MaterialValidationUpdateRequest {
  export type StatusEnum =
    | 'pending'
    | 'approved'
    | 'changes_requested'
    | 'rejected';
  export const StatusEnum = {
    Pending: 'pending' as StatusEnum,
    Approved: 'approved' as StatusEnum,
    ChangesRequested: 'changes_requested' as StatusEnum,
    Rejected: 'rejected' as StatusEnum,
  };
}
