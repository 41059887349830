import { Pipe, PipeTransform } from '@angular/core';
import { ColumnConfig } from '../../types/table-config';

@Pipe({
  name: 'getTableValueByKey',
  standalone: true,
})
export class GetTableValueByKeyPipe implements PipeTransform {
  transform<Row extends object>(config: ColumnConfig<Row>, data: Row) {
    if (typeof config.key === 'function') {
      return config.key(data);
    }

    if (typeof config.key === 'string') {
      return data[config.key];
    }

    if (typeof config.html === 'function') {
      return config.html(data);
    }

    return 'N/A';
  }
}
