import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatType } from '../../enums/date-format';
import { transformDate } from '../../helpers/transformDate';

@Pipe({
  name: 'dateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  transform(
    date: string | Date | number,
    dateFormat: DateFormatType = DateFormatType.MonthDayYearLineHour,
  ): string {
    const value = new Date(date);
    return transformDate(value, dateFormat);
  }
}
