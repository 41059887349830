import { Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarRef, MatSnackBarLabel } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Notification } from '@cat-ai-us-fe/api';
import { NotificationService } from '@cat-ai-us-fe/shared/data-access';
import { NotificationIconPipe } from '@cat-ai-us-fe/shared/util';

interface NotificationCartData {
  notification: Notification;
  onDismiss: () => void;
  title?: string;
  message?: string;
  type?: string;
}

@Component({
  selector: 'cat-ai-notification-card',
  standalone: true,
  imports: [
    CommonModule,
    MatSnackBarLabel,
    MatButton,
    MatIcon,
    NotificationIconPipe,
  ],
  templateUrl: './notification-card.component.html',
})
export class NotificationCardComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: NotificationCartData,
    public notificationsService: NotificationService,
  ) {}
  snackBarRef = inject(MatSnackBarRef);
  dismiss() {
    if (this.data.onDismiss) {
      this.data.onDismiss();
    }

    this.snackBarRef.dismissWithAction();
  }
}
