/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UploadParams } from './upload-params';

export interface FilledFormAttachment {
  readonly id: number;
  readonly uuid: string | null;
  original_name?: string | null;
  filledform: number;
  question: number;
  content_type?: string;
  /**
   * * `not_uploaded` - Not Uploaded * `upload_success` - Upload Success * `upload_error` - Upload Error
   */
  upload_status?: FilledFormAttachment.UploadStatusEnum;
  readonly created_by: number;
  readonly created_at: string;
  readonly updated_at: string;
  readonly access_url: string;
  readonly upload_params: UploadParams;
}
export namespace FilledFormAttachment {
  export type UploadStatusEnum =
    | 'not_uploaded'
    | 'upload_success'
    | 'upload_error';
  export const UploadStatusEnum = {
    NotUploaded: 'not_uploaded' as UploadStatusEnum,
    UploadSuccess: 'upload_success' as UploadStatusEnum,
    UploadError: 'upload_error' as UploadStatusEnum,
  };
}
