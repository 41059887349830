import { Injectable } from '@angular/core';
import { CompaniesService, UsersService } from '@cat-ai-us-fe/api';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private userService: UsersService,
    private companiesService: CompaniesService,
  ) {}

  getCurrentUser(id: number) {
    return this.userService.usersRetrieve({ id });
  }

  getCurrentCompany(id: number) {
    return this.companiesService.companiesRetrieve({ id });
  }
}
