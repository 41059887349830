import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Notification } from '@cat-ai-us-fe/api';
import { NotificationIconPipe, TimeAgoPipe } from '@cat-ai-us-fe/shared/util';

@Component({
  selector: 'cat-ai-notifications-list',
  standalone: true,
  imports: [MatIconModule, TitleCasePipe, TimeAgoPipe, NotificationIconPipe],
  templateUrl: './notifications-list.component.html',
})
export class NotificationsListComponent {
  @Input() notifications!: Notification[] | null;
  @Input() showTimeAgo = false;
  @Input() wrapperClasses = '';
  @Output() dismiss = new EventEmitter();
  @Output() navigateTo = new EventEmitter();
}
