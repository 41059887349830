import { Directive, Input } from '@angular/core';
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-icon[catLazyLoadIcon]',
  standalone: true,
})
export class LazyLoadIconDirective {
  @Input()
  set catLazyLoadIcon(icon: string) {
    this.iconRegistry.addSvgIcon(
      icon,
      this.sanitizer.bypassSecurityTrustResourceUrl(`assets/${icon}.svg`),
    );
    this._matIcon.svgIcon = icon;
  }

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private readonly _matIcon: MatIcon,
  ) {}
}
