import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Subject, debounceTime, takeUntil } from 'rxjs';

@Component({
  selector: 'cat-ai-search',
  standalone: true,
  imports: [FormsModule, MatFormFieldModule, MatIconModule, MatInputModule],
  templateUrl: './search.component.html',
})
export class SearchComponent {
  @Input() placeholder = 'Search';
  @Input() wrapperClasses!: string;
  @Output() search = new EventEmitter<string>();
  searchParam!: string;
  debounceTimeMs = 500;
  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.debounceSearch();
  }

  onSearch() {
    this.searchSubject.next(this.searchParam);
  }

  ngOnDestroy() {
    this.destroy$.complete();
    this.destroy$.next();
  }

  private debounceSearch() {
    this.searchSubject
      .pipe(debounceTime(this.debounceTimeMs), takeUntil(this.destroy$))
      .subscribe((searchValue) => {
        this.search.emit(searchValue);
      });
  }
}
