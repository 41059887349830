<div class="container bg-white flex flex-col h-full">
  <ul
    class="tab-slider--tabs w-fit flex border border-link-water rounded-md p-1.5 bg-{{
      color
    }}"
    role="tablist"
  >
    @for (tab of tabs; track tab; let index = $index) {
      <li role="tab">
        <button
          mat-button
          [color]="selectedTabIndex === index ? 'accent' : 'clear'"
          (click)="setActiveTab(index)"
          class="btn-lg"
          [ngClass]="{ '!bg-blue-50': selectedTabIndex === index }"
        >
          {{ tab.label }}
        </button>
      </li>
    }
  </ul>
  <div class="grow">
    @switch (selectedTabIndex) {
      @case (firstTabIndex) {
        <ng-content select="[firstTab]"></ng-content>
      }
      @case (secondTabIndex) {
        <ng-content select="[secondTab]"></ng-content>
      }
    }
  </div>
</div>
