<!-- Notice: Tailwind compiles classes during build, not during runtime -->
<span
  class="inline-block px-2 py-0.5 rounded-full font-medium text-xs max-w-full overflow-hidden text-ellipsis"
  [ngClass]="{
    '!bg-transparent border': variant === 'outlined',
    'cat-ai-chip-blue': color === 'blue',
    'cat-ai-chip-green': color === 'green',
    'cat-ai-chip-purple': color === 'purple',
    'cat-ai-chip-violet': color === 'violet',
    'cat-ai-chip-red': color === 'red',
    'cat-ai-chip-gray': color === 'gray',
    'cat-ai-chip-orange': color === 'orange',
  }"
  [title]="
    row && bindedProperty && row[bindedProperty] ? row[bindedProperty] : 'N/A'
  "
>
  @if (row && bindedProperty && row[bindedProperty]) {
    {{ row[bindedProperty] | titlecase | replaceUnderscore }}
  } @else {
    <ng-content></ng-content>
  }
</span>
