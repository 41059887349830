import { Pipe, PipeTransform } from '@angular/core';
import { Notification } from '@cat-ai-us-fe/api';

@Pipe({
  name: 'notificationIcon',
  standalone: true,
})
export class NotificationIconPipe implements PipeTransform {
  transform(notification: Notification): string | undefined {
    let icon;
    if (notification.urgency === Notification.UrgencyEnum.High) {
      icon = 'priority_high';
    } else if (notification.urgency === Notification.UrgencyEnum.Medium) {
      icon = 'notifications';
    } else {
      switch (notification.kind) {
        case Notification.KindEnum.Training:
          icon = 'school';
          break;
        case Notification.KindEnum.Task:
          icon = 'list_alt';
          break;

        case Notification.KindEnum.Material:
          icon = 'insert_drive_file';
          break;

        case Notification.KindEnum.General:
          icon = 'mark_unread_chat_alt';
          break;
      }
    }
    return icon;
  }
}
