import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'cat-ai-collapsible-card',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './collapsible-card.component.html',
})
export class CollapsibleCardComponent {
  @Input() title = '';
  @Input() wrapperClasses = '';
}
