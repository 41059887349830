import { MatButton, MatIconButton } from '@angular/material/button';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const tepmlateToPdf = (
  input: HTMLElement | null,
  filename: string,
  btn?: MatButton | MatIconButton,
  openPrintWindow = false,
) => {
  if (!input) {
    return;
  }
  html2canvas(input, {
    useCORS: true,
    scrollY: 0,
    scale: 2,
    allowTaint: true,
  }).then((canvas) => {
    const image = { type: 'image/jpeg', quality: 1.0 };
    const margin = [0.5, 0.5];

    const imgWidth = 8.5;
    let pageHeight = 11;

    const innerPageWidth = imgWidth - margin[0] * 2;
    const innerPageHeight = pageHeight - margin[1] * 2;

    // Calculate the number of pages.
    const pxFullHeight = canvas.height;
    const pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
    const nPages = Math.ceil(pxFullHeight / pxPageHeight);

    // Define pageHeight separately so it can be trimmed on the final page.
    pageHeight = innerPageHeight;

    // Create a one-page canvas to split up the full image.
    const pageCanvas = document.createElement('canvas');
    const pageCtx = pageCanvas.getContext('2d');
    pageCanvas.width = canvas.width;
    pageCanvas.height = pxPageHeight;

    // Initialize the PDF.
    const pdf = new jsPDF('p', 'in', [8.5, 11]);

    for (let page = 0; page < nPages; page++) {
      // Trim the final page to reduce file size.
      if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
        pageCanvas.height = pxFullHeight % pxPageHeight;
        pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
      }

      // Display the page.
      const w = pageCanvas.width;
      const h = pageCanvas.height;
      if (!pageCtx) {
        return;
      }
      pageCtx.fillStyle = 'white';
      pageCtx.fillRect(0, 0, w, h);
      pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);
      pageCtx.imageSmoothingEnabled = false;

      // Add the page to the PDF.
      if (page > 0) pdf.addPage();
      const imgData = pageCanvas.toDataURL(
        'image/' + image.type,
        image.quality,
      );
      pdf.addImage(
        imgData,
        image.type,
        margin[1],
        margin[0],
        innerPageWidth,
        pageHeight,
      );
    }

    if (openPrintWindow) {
      pdf.autoPrint();
      window.open(
        URL.createObjectURL(pdf.output('blob')),
        '_blank',
        'height=650,width=1000,scrollbars=yes,location=yes',
      );
    } else {
      pdf.save(filename, { returnPromise: true }).then((a) => {
        if (btn) {
          btn.disabled = false;
        }
      });
    }
  });
};
