import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray',
  standalone: true,
})
export class EnumToArrayPipe implements PipeTransform {
  transform(value: any): string[] {
    return Object.keys(value)?.filter((v) => isNaN(Number(v)));
  }
}
