import { Pipe, PipeTransform } from '@angular/core';
import { ColumnConfig } from '../../types/table-config';

@Pipe({
  name: 'getTableComponentInputs',
  standalone: true,
})
export class GetTableComponentInputsPipe implements PipeTransform {
  transform(row: any, column: ColumnConfig<any>): any {
    if (column.inputs) {
      return {
        row,
        ...column.inputs,
      };
    }

    return {
      row,
    };
  }
}
