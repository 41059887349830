import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, forkJoin, map, switchMap, throwError } from 'rxjs';
import {
  DownloadService,
  UploadRetrieve200Response,
  UploadService,
} from '@cat-ai-us-fe/api';

@Injectable({
  providedIn: 'root',
})
export class UploadAttachmentsService {
  constructor(
    private httpClient: HttpClient,
    private uploadService: UploadService,
    private downloadService: DownloadService,
  ) {}

  resolveAttachmentUrl(objectName: string) {
    return this.downloadService
      .downloadRetrieve({ objectName })
      .pipe(map((res) => res.url));
  }

  uploadAttachments(files: File[]) {
    const obs$: Observable<string>[] = [];
    files.forEach((file) => {
      if (!file.type) {
        obs$.push(EMPTY);
      } else {
        const req = this.uploadService
          .uploadRetrieve({ objectName: file.name })
          .pipe(switchMap((res) => this.uploadObject(file, res as any)));
        obs$.push(req);
      }
    });
    return forkJoin(obs$);
  }

  uploadObject(
    file: File,
    config: UploadRetrieve200Response & {
      fields?: { [key: string]: string };
    },
  ) {
    const formData = new FormData();
    if (!config.fields || !config.url) {
      return throwError(() => 'err');
    }

    formData.append('key', config.fields.key);
    formData.append('x-amz-algorithm', config.fields['x-amz-algorithm']);
    formData.append('x-amz-signature', config.fields['x-amz-signature']);
    formData.append('x-amz-credential', config.fields['x-amz-credential']);
    formData.append('x-amz-date', config.fields['x-amz-date']);
    formData.append('policy', config.fields.policy);
    formData.append('file', file);

    return this.httpClient
      .post(config.url, formData)
      .pipe(map(() => `${config.url}${config.fields?.key}`));
  }
}
