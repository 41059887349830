import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { ActiveUserState } from '../../store/active-user.state';
import { User } from '@cat-ai-us-fe/api';

@Directive({
  selector: '[catAiSupervisorAccess]',
  standalone: true,
})
export class SupervisorAccessDirective {
  private templateRef = inject(TemplateRef);
  private viewContainer = inject(ViewContainerRef);
  private store = inject(Store);

  constructor() {
    const role = this.store.selectSnapshot(ActiveUserState.getRole);

    if (role && role === User.RoleEnum.Supervisor) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
