<div class="confirm-modal">
  <h2 class="confirm-modal__heading">Add feedback</h2>

  <mat-dialog-content>
    <mat-form-field class="w-[350px]">
      <textarea
        matInput
        placeholder="Write a feedback"
        [formControl]="reviewControl"
        rows="4"
      ></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      mat-flat-button
      color="basic"
      [mat-dialog-close]="false"
      class="flex-1"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="accent"
      [mat-dialog-close]="reviewControl.value"
      class="flex-1"
      [disabled]="!reviewControl.value"
    >
      Send
    </button>
  </mat-dialog-actions>

  <button
    type="button"
    mat-icon-button
    [mat-dialog-close]="false"
    class="!absolute top-2 right-1"
  >
    <mat-icon class="text-gray-500">close</mat-icon>
  </button>
</div>
