export const fileNameGenerator = () => {
  let filename = 'Document_';
  filename += `${new Date().toLocaleDateString()}.pdf`;
  return filename;
};

export const downloadDocument = (href: string) => {
  const link = document.createElement('a');
  link.href = href;
  link.download = fileNameGenerator();
  link.click();
};

export function printDocument(href: string) {
  window.open(href, '_blank', 'location=no');
}
