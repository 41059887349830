import { Pipe, PipeTransform, inject } from '@angular/core';
import { UploadAttachmentsService } from '../../services/upload-attachments.service';
import { getFileNameFromUrl } from '@cat-ai-us-fe/shared/util';
import { AsyncPipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { EMPTY, Observable } from 'rxjs';

@Pipe({
  name: 'resolveAttachmentUrl',
  standalone: true,
})
export class ResolveAttachmentUrlPipe implements PipeTransform {
  uploadService = inject(UploadAttachmentsService);
  asyncPipe = inject(AsyncPipe);
  sanitizer = inject(DomSanitizer);

  transform(
    awsStorageUrl: string | null | undefined,
  ): Observable<string | undefined> {
    if (!awsStorageUrl) {
      return EMPTY;
    }

    const filename = getFileNameFromUrl(awsStorageUrl);
    return this.uploadService.resolveAttachmentUrl(filename);
  }
}
