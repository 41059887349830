export enum FormType {
  Collaboration = 'collaboration-record',
  ContractServices = 'contract-services-record',
  CustomerComplaints = 'customer-complaints',
  EmergencyContacts = 'emergency-contacts-record',
  FoodDefenseChallenge = 'food-defense-challenge-input',
  IncidentReport = 'incident-report',
  Inspection = 'inspection-record',
  ManReview = 'management-review',
  ParticipantTraining = 'participant-training-record',
  PersonnelCertificate = 'personnel-certificate-upload',
  RiskAssessment = 'risk-assessment-input',
  ShelfLife = 'shelf-life',
  TrainingMaterials = 'training-materials-record',
  VerificationCriteria = 'verification-criteria-record',
}
