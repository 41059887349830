/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Assignee } from './assignee';
import { Comment } from './comment';
import { MaterialLink } from './material-link';

export interface TaskSchedule {
  readonly id: number;
  title: string;
  /**
   * * `daily` - Daily * `weekly` - Weekly * `monthly` - Monthly * `quarterly` - Quarterly * `yearly` - Yearly
   */
  frequency?: TaskSchedule.FrequencyEnum;
  start_date?: string;
  start_time: string;
  end_date?: string | null;
  end_time?: string;
  readonly assignee: Assignee;
  supervisor?: number | null;
  created_by: number;
  scope?: string;
  readonly materials: Array<MaterialLink>;
  readonly comments: Array<Comment>;
}
export namespace TaskSchedule {
  export type FrequencyEnum =
    | 'daily'
    | 'weekly'
    | 'monthly'
    | 'quarterly'
    | 'yearly';
  export const FrequencyEnum = {
    Daily: 'daily' as FrequencyEnum,
    Weekly: 'weekly' as FrequencyEnum,
    Monthly: 'monthly' as FrequencyEnum,
    Quarterly: 'quarterly' as FrequencyEnum,
    Yearly: 'yearly' as FrequencyEnum,
  };
}
