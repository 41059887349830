import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'cat-ai-upload-attachment-modal',
  standalone: true,
  imports: [MatDialogModule, FormlyModule, MatButtonModule],
  templateUrl: './upload-attachment-modal.component.html',
})
export class UploadAttachmentModalComponent {
  model = { attachment: null };
  fields = [
    {
      key: 'attachment',
      type: 'cat-ai-file',
      props: {
        hideAllowedExtension: true,
        extensionsText: '.type of materials to upload  (max. 800x400px)',
        labelClass: 'min-h-[230px]',
        multiple: true,
      },
    },
  ];
  form = new FormGroup({});
}
