/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Notification {
  readonly id: number;
  user: number;
  /**
   * * `low` - Low * `medium` - Medium * `high` - High
   */
  urgency?: Notification.UrgencyEnum;
  title?: string;
  /**
   * * `general` - General * `task` - Task * `training` - Training * `material` - Material
   */
  kind: Notification.KindEnum;
  related?: number | null;
  /**
   * * `DOCUMENT` - Document * `FORM` - Form * `FILE` - File * `FLOWCHART` - Flowchart * `CERTIFICATE` - Certificate
   */
  related_type?: Notification.RelatedTypeEnum | null;
  message: string;
  readonly created_at: string;
}
export namespace Notification {
  export type UrgencyEnum = 'low' | 'medium' | 'high';
  export const UrgencyEnum = {
    Low: 'low' as UrgencyEnum,
    Medium: 'medium' as UrgencyEnum,
    High: 'high' as UrgencyEnum,
  };
  export type KindEnum = 'general' | 'task' | 'training' | 'material';
  export const KindEnum = {
    General: 'general' as KindEnum,
    Task: 'task' as KindEnum,
    Training: 'training' as KindEnum,
    Material: 'material' as KindEnum,
  };
  export type RelatedTypeEnum =
    | 'DOCUMENT'
    | 'FORM'
    | 'FILE'
    | 'FLOWCHART'
    | 'CERTIFICATE'
    | ''
    | 'null';
  export const RelatedTypeEnum = {
    Document: 'DOCUMENT' as RelatedTypeEnum,
    Form: 'FORM' as RelatedTypeEnum,
    File: 'FILE' as RelatedTypeEnum,
    Flowchart: 'FLOWCHART' as RelatedTypeEnum,
    Certificate: 'CERTIFICATE' as RelatedTypeEnum,
    Empty: '' as RelatedTypeEnum,
    Null: 'null' as RelatedTypeEnum,
  };
}
