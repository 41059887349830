/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ShallowUser } from './shallow-user';
import { ShallowMaterial } from './shallow-material';

export interface MaterialChange {
  readonly material: ShallowMaterial;
  readonly changed_by: ShallowUser;
  readonly changed_at: string;
  /**
   * * `view` - View * `edit_created` - Edit Created * `edit_updated` - Edit Updated * `edit_new_version` - Edit New Version
   */
  change_type?: MaterialChange.ChangeTypeEnum;
  fields?: Array<string>;
}
export namespace MaterialChange {
  export type ChangeTypeEnum =
    | 'view'
    | 'edit_created'
    | 'edit_updated'
    | 'edit_new_version';
  export const ChangeTypeEnum = {
    View: 'view' as ChangeTypeEnum,
    EditCreated: 'edit_created' as ChangeTypeEnum,
    EditUpdated: 'edit_updated' as ChangeTypeEnum,
    EditNewVersion: 'edit_new_version' as ChangeTypeEnum,
  };
}
