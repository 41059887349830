import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { User } from '@cat-ai-us-fe/api';
import { Store } from '@ngxs/store';
import { ActiveUserState } from '../../store/active-user.state';

@Directive({
  selector: '[catAiRoleAccess]',
  standalone: true,
})
export class RoleAccessDirective {
  private hasView = false;
  private templateRef = inject(TemplateRef);
  private viewContainer = inject(ViewContainerRef);
  private store = inject(Store);

  @Input() set catAiRoleAccess(roles: User.RoleEnum[]) {
    if (!roles.length) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      return;
    }

    const role = this.store.selectSnapshot(ActiveUserState.getRole);
    const condition = roles && role && roles.includes(role);
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
