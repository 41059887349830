/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TrainingCertificate } from './training-certificate';

export interface CertificatePublic {
  readonly id: number;
  readonly training: TrainingCertificate;
  user: number;
  readonly created_at: string;
  valid_until?: string | null;
  readonly uuid: string;
  /**
   * * `passed` - Passed * `failed` - Failed * `past_due` - Past due
   */
  result?: CertificatePublic.ResultEnum;
  readonly qr_code_url: string;
}
export namespace CertificatePublic {
  export type ResultEnum = 'passed' | 'failed' | 'past_due';
  export const ResultEnum = {
    Passed: 'passed' as ResultEnum,
    Failed: 'failed' as ResultEnum,
    PastDue: 'past_due' as ResultEnum,
  };
}
