import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgComponentOutlet } from '@angular/common';

@Component({
  selector: 'cat-ai-tabs',
  standalone: true,
  imports: [MatButtonModule, NgClass, NgComponentOutlet],
  templateUrl: './tabs.component.html',
})
export class TabsComponent {
  @Input() tabs!: { label: string; componentRef: any }[];
  @Input() color!: string;
  firstTabIndex = 0;
  secondTabIndex = 1;
  selectedTabIndex: number = this.firstTabIndex;
  @Output() clicked = new EventEmitter();

  setActiveTab(index: number) {
    this.selectedTabIndex = index;
    this.clicked.emit(index);
  }
}
