/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PatchedFilledFormAttachmentRequest {
  original_name?: string | null;
  filledform?: number;
  question?: number;
  content_type?: string;
  /**
   * * `not_uploaded` - Not Uploaded * `upload_success` - Upload Success * `upload_error` - Upload Error
   */
  upload_status?: PatchedFilledFormAttachmentRequest.UploadStatusEnum;
}
export namespace PatchedFilledFormAttachmentRequest {
  export type UploadStatusEnum =
    | 'not_uploaded'
    | 'upload_success'
    | 'upload_error';
  export const UploadStatusEnum = {
    NotUploaded: 'not_uploaded' as UploadStatusEnum,
    UploadSuccess: 'upload_success' as UploadStatusEnum,
    UploadError: 'upload_error' as UploadStatusEnum,
  };
}
