import { Pipe, PipeTransform } from '@angular/core';
import { getFileNameFromUrl } from '../../helpers/file-name';

@Pipe({
  name: 'fileNameFromUrl',
  standalone: true,
})
export class FileNameFromUrlPipe implements PipeTransform {
  transform(value: string): string {
    return getFileNameFromUrl(value);
  }
}
