import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTime',
  standalone: true,
})
export class SecondsToTimePipe implements PipeTransform {
  transform(timestamp = 0, showSeconds = false): unknown {
    const hours = Math.floor(timestamp / 60 / 60);

    const minutes = Math.floor(timestamp / 60) - hours * 60;
    let formatted = '';
    if (hours) {
      formatted += `${hours.toString().padStart(2, '0')}h `;
    }
    formatted += `${minutes.toString().padStart(2, '0')}m`;
    if (showSeconds) {
      const seconds = Math.floor(timestamp % 60);
      formatted += ` ${seconds.toString().padStart(2, '0')}s`;
    }

    return formatted;
  }
}
