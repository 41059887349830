/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GenerateQuestionRequest } from './generate-question-request';

export interface GenerateQuizRequest {
  name: string;
  description: string;
  questions: Array<GenerateQuestionRequest>;
  materials: Array<number>;
  num_questions: number;
  num_variants: number;
  /**
   * * `easy` - Easy * `medium` - Medium * `hard` - Hard
   */
  difficulty: GenerateQuizRequest.DifficultyEnum;
  prompt?: string;
  seconds_to_complete?: number;
}
export namespace GenerateQuizRequest {
  export type DifficultyEnum = 'easy' | 'medium' | 'hard';
  export const DifficultyEnum = {
    Easy: 'easy' as DifficultyEnum,
    Medium: 'medium' as DifficultyEnum,
    Hard: 'hard' as DifficultyEnum,
  };
}
