import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '@cat-ai-us-fe/shared/util';

@Injectable({
  providedIn: 'root',
})
export class FormFieldService {
  public readonly envUrl: string = inject(ENVIRONMENT_TOKEN);

  constructor(private readonly httpClient: HttpClient) {}

  getRequestFieldOptions(url: string) {
    return this.httpClient.get<{ results: Record<string, string | number>[] }>(
      `${this.envUrl}${url}`,
    );
  }
}
