/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Task {
  readonly id: number;
  title: string;
  scope?: string;
  readonly created_at: string;
  start_date: string;
  end_date: string;
  /**
   * * `todo` - Todo * `in_progress` - In Progress * `submitted` - Submitted * `not_started` - Not Started * `delayed` - Delayed * `late_submission` - Late Submission * `abandoned` - Abandoned * `completed` - Completed * `failed` - Failed
   */
  readonly internal_status: Task.InternalStatusEnum;
  /**
   * * `todo` - Todo * `in_progress` - In Progress * `submitted` - Submitted * `completed` - Completed * `failed` - Failed
   */
  external_status?: Task.ExternalStatusEnum;
  created_by?: number;
  supervisor?: number | null;
  assignee?: number | null;
}
export namespace Task {
  export type InternalStatusEnum =
    | 'todo'
    | 'in_progress'
    | 'submitted'
    | 'not_started'
    | 'delayed'
    | 'late_submission'
    | 'abandoned'
    | 'completed'
    | 'failed';
  export const InternalStatusEnum = {
    Todo: 'todo' as InternalStatusEnum,
    InProgress: 'in_progress' as InternalStatusEnum,
    Submitted: 'submitted' as InternalStatusEnum,
    NotStarted: 'not_started' as InternalStatusEnum,
    Delayed: 'delayed' as InternalStatusEnum,
    LateSubmission: 'late_submission' as InternalStatusEnum,
    Abandoned: 'abandoned' as InternalStatusEnum,
    Completed: 'completed' as InternalStatusEnum,
    Failed: 'failed' as InternalStatusEnum,
  };
  export type ExternalStatusEnum =
    | 'todo'
    | 'in_progress'
    | 'submitted'
    | 'completed'
    | 'failed';
  export const ExternalStatusEnum = {
    Todo: 'todo' as ExternalStatusEnum,
    InProgress: 'in_progress' as ExternalStatusEnum,
    Submitted: 'submitted' as ExternalStatusEnum,
    Completed: 'completed' as ExternalStatusEnum,
    Failed: 'failed' as ExternalStatusEnum,
  };
}
