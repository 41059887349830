import { MaterialChange } from '@cat-ai-us-fe/api';
import {
  ColumnConfig,
  DateFormatType,
  transformDate,
} from '@cat-ai-us-fe/shared/util';

export const columns: ColumnConfig<MaterialChange>[] = [
  {
    label: 'author',
    html: (row) => row.material?.created_by?.fullname || '-',
  },
  {
    label: 'action',
    html: (row) =>
      (row.change_type &&
        row.change_type.split('_') &&
        row.change_type.split('_')[0]) ||
      '-',
  },
  {
    label: 'CHANGE TYPE',
    html: (row) =>
      (row.change_type &&
        row.change_type.split('_') &&
        row.change_type.split('_')[1]) ||
      '-',
  },
  {
    html: (row) => row.fields?.join(', ') || '-',
    label: 'changed',
  },
  {
    html: (row) => row.changed_by.fullname || '-',
    label: 'changed by',
  },
  {
    label: 'changed at',
    html: (row) =>
      row.changed_at
        ? transformDate(row.changed_at, DateFormatType.DayMonthYearLinear)
        : '-',
  },
];
