/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuizAnswer } from './quiz-answer';
import { Certificate } from './certificate';

export interface Attempt {
  readonly id: number;
  training: number;
  user: number;
  readonly created_at: string;
  /**
   * * `in_progress` - In progress * `completed` - Completed * `failed` - Failed * `passed` - Passed
   */
  status?: Attempt.StatusEnum;
  answers: Array<QuizAnswer>;
  readonly start_time: string;
  readonly end_time: string | null;
  readonly certificate: Certificate;
}
export namespace Attempt {
  export type StatusEnum = 'in_progress' | 'completed' | 'failed' | 'passed';
  export const StatusEnum = {
    InProgress: 'in_progress' as StatusEnum,
    Completed: 'completed' as StatusEnum,
    Failed: 'failed' as StatusEnum,
    Passed: 'passed' as StatusEnum,
  };
}
