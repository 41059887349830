<div class="confirm-modal">
  <h2 class="confirm-modal__heading">
    {{ data.title }}
  </h2>

  @if (!!data.text) {
    <mat-dialog-content>
      {{ data.text }}
    </mat-dialog-content>
  }

  <mat-dialog-actions>
    <div class="w-full flex fle-row items-center justify-end">
      <button mat-flat-button color="basic" [matDialogClose]="false">
        {{ data.discardButtonText || 'Cancel' }}
      </button>
      <button mat-flat-button color="accent" [mat-dialog-close]="true">
        {{ data.confirmButtonText || 'Confirm' }}
      </button>
    </div>
  </mat-dialog-actions>

  <button
    type="button"
    mat-icon-button
    [mat-dialog-close]="null"
    class="!absolute top-2 right-1"
  >
    <mat-icon class="text-gray-500">close</mat-icon>
  </button>
</div>
