<mat-form-field class="max-w-[400px] w-full {{ wrapperClasses }}">
  <mat-icon
    matIconPrefix
    class="material-icons-outlined"
    (click)="search.emit(searchParam)"
    >search</mat-icon
  >
  <input
    matInput
    [placeholder]="placeholder"
    [(ngModel)]="searchParam"
    (input)="onSearch()"
    name="search"
  />
</mat-form-field>
